<template>
  <div class="login_container">
    <el-button type="info" @click="goHome">首页</el-button>
    <div class="login_box">
      <el-form
          ref="loginRefs"
          class="login-form"
          :model="loginForms"
          :rules="loginRules"
      >
        <h1 class="title">修改密码</h1>
        <el-form-item label="" prop="oldpassword">
          <el-input type="text" v-model="loginForms.oldpassword" autocomplete="off" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="" prop="newpassword">
          <el-input type="text" v-model="loginForms.newpassword" autocomplete="off" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item class="subtn">
          <el-button type="primary"    @click="doSubmit()">确定</el-button>
          <el-button type="info"    @click="resSubmit()">重置</el-button>
        </el-form-item>
        <el-row style="text-align: center; margin-top: -10px;margin-bottom: 8px">
          <!--          <el-link type="primary" class="retxt" @click="doRegister()">没有账号？立即注册</el-link>-->
        </el-row>
      </el-form>
    </div>
  </div>

</template>

<script>
export default {
  name: "Login",
  data () {
    //验证手机号是否合法
    // let isPhone= (rule, value, callback) => {
    //   if (!value) {
    //     return new Error("请输入电话号码");
    //   } else {
    //     const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
    //     const isPhone = reg.test(value);
    //     value = Number(value); //转换为数字
    //     if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
    //       value = value.toString(); //转换成字符串
    //       if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
    //         callback(new Error("手机号码格式有误"));
    //       } else {
    //         callback();
    //       }
    //     } else {
    //       callback(new Error("请输入正确的电话号码"));
    //     }
    //   }
    // };
    return {
      role:{},
      //登录表单数据绑定对象
      loginForms:{
        oldpassword:'',
        newpassword:'',

      },
      //登陆表单验证规则
      loginRules:{
        oldpassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
        ],
        newpassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    //提交修改
    doSubmit() {
      //提交登录预校验，返回布尔值
      this.$refs.loginRefs.validate(async (valid) => {
        console.log(valid)
        //若为false,直接return
        if(!valid) return;

        // 若为true,发起网络请求
        const {data : res} = await this.$http.post('/user/editpassword/', this.loginForms)
        console.log(res);

        if (res.code !==1) return this.$message.error(res.msg);
        this.$message.success(res.msg);

        const pingtaiId = window.sessionStorage.getItem('pingtaiId');


        this.pingtaiId = pingtaiId


        console.log(pingtaiId)
        //跳转home页面
        await this.$router.push('/home');
      })
    },
    //重置表单
    resSubmit() {
      this.$refs.loginRefs.resetFields()

    },


    goHome() {

    },

  },
  watch:{

  },
}
</script>

<style lang="less" scoped>
.login_container {
  height: 100%;
  background-color: #BBBBBB;
  //背景渐变效果
  //background-image: linear-gradient(#e66465, #9198e5);
  //自定义背景图
  //background-size:100% 100%;
  //background-repeat: no-repeat;
  //background-image: url("../../assets/login2.webp");
}

.login_box{
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 320px;
  background: #ffffff;
  border-radius: 10px;

}

.login-form {
  position: absolute;
  bottom: 0;
  width: 100%;

  padding: 0 20px;
  box-sizing: border-box;
}

.title {
  text-align: center;

}

.subtn {
  display: flex;
  justify-content: center;
}



</style>
